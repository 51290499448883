import React from "react";
import { Button, Container, Row, Col, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const WelcomeUser = () => {
  const history = useHistory();

  const handleViewResults = () => {
    history.push("/user/home");
  };

  const handleTakeTest = () => {
    history.push("/user/test");
  };

  return (
    <Container fluid>
      <Row className="justify-content-center mt-5">
        <Col md="8">
          <Card>
            <Card.Body className="text-center">
              <h3 className="mb-4">Que voulez-vous faire aujourd'hui ?</h3>
              <div className="d-grid gap-3">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={handleViewResults}
                  className="text-white">
                  Voir mes résultats
                </Button>
                <div
                  style={{
                    margin: "20px 0",
                  }}
                />
                <Button
                  variant="primary"
                  size="lg"
                  onClick={handleTakeTest}
                  className="text-white">
                  Passer un test
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WelcomeUser;
